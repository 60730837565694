import axios from '@/http/axios'
import {createCancelTokenHandler} from "../../axios/utils";

const cancelTokenHandlerObject = {
  getSaleInvoices: createCancelTokenHandler('getSaleInvoices'),
  getReservableTimes: createCancelTokenHandler('getReservableTimes'),
  getReserveTimeInvoices: createCancelTokenHandler('getReserveTimeInvoices'),
  getSaleInvoiceActivitiesLog: createCancelTokenHandler('getSaleInvoiceActivitiesLog'),
  getSaleInvoicesTrash: createCancelTokenHandler('getSaleInvoicesTrash')
}

function makeQuery (page = 1, filters = [], sorts = []) {
  let queries = []

  if (page > 1) queries.push(`page=${page}`)

  if (filters.length) queries = queries.concat(...filters)

  if (sorts.length) queries = queries.concat(...sorts)

  return queries
}

export function getSaleInvoices (page, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/booking/sale-invoices${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getSaleInvoices'].handleRequestCancellation().token
  })
}

export function getReservableTimes (page, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `&${  queries.join('&')}`

  return axios({
    url: `v1/admin/booking/reservable-times?${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getReservableTimes'].handleRequestCancellation().token
  })
}

export function getReserveTimeInvoices (page, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `&${  queries.join('&')}`

  return axios({
    url: `v1/admin/booking/reservable-time-invoices?${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getReserveTimeInvoices'].handleRequestCancellation().token
  })
}

export function createSaleInvoiceDocument (id) {

  return axios({
    url: `v1/admin/booking/sale-invoices/${id}/document`,
    method: 'post'
  })
}

export function getSaleInvoice (payload) {

  return axios({
    url: `v1/admin/booking/sale-invoices/${payload.id}`,
    method: 'get'
  })
}

export function getSaleInvoiceActivitiesLog (id, page, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`
  return axios({
    url: `v1/admin/booking/sale-invoices/${id}/activity-logs${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getSaleInvoiceActivitiesLog'].handleRequestCancellation().token
  })
}

export function insertSaleInvoice (payload) {

  return axios({
    url: 'v1/admin/booking/sale-invoices',
    data: payload,
    method: 'post'
  })
}

export function editSaleInvoice (id, payload) {

  return axios.patch(`v1/admin/booking/sale-invoices/${id}`, payload)
}

export function refreshSaleInvoiceStatus (payload) {

  return axios({
    url: `v1/admin/booking/sale-invoices/${payload.id}/refresh-status`,
    data: payload,
    method: 'post'
  })
}

export function completeSaleInvoice (id) {

  return axios({
    url: `v1/admin/booking/sale-invoices/${id}/complete`,
    method: 'post'
  })
}

export function sendMessageSaleInvoice (id) {

  return axios({
    url: `v1/admin/booking/sale-invoices/${id}/send-message`,
    method: 'post'
  })
}

export function insertSaleInvoiceAddon (id, payload) {

  return axios({
    url: `v1/admin/booking/sale-invoices/${id}/addons`,
    data: payload,
    method: 'post'
  })
}

export function cancelSaleInvoice (id, payload) {

  return axios({
    url: `v1/admin/booking/sale-invoices/${id}/cancel`,
    data: payload,
    method: 'post'
  })
}

export function calculateCancelSaleInvoicePrice (id, payload) {

  return axios({
    url: `v1/admin/booking/sale-invoices/${id}/calculate-cancel-price`,
    data: payload,
    method: 'post'
  })
}

export function cancelSaleAddon (invoiceId, addonId, payload) {

  return axios({
    url: `v1/admin/booking/sale-invoices/${invoiceId}/addons/${addonId}/cancel`,
    data: payload,
    method: 'post'
  })
}

export function deleteSaleInvoice (id, force = false) {

  return axios({
    url: `v1/admin/booking/sale-invoices/${id}${force ? '/force' : ''}`,
    method: 'delete'
  })
}

export function restoreSaleInvoice (id) {

  return axios({
    url: `v1/admin/booking/sale-invoices/${id}/restore`,
    method: 'put'
  })
}


export function getSaleInvoicesTrash (page, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `&${  queries.join('&')}`
  return axios({
    url: `v1/admin/booking/sale-invoices?trashed=true${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getSaleInvoicesTrash'].handleRequestCancellation().token
  })
}
